(function () {
  'use strict';

  class PreviewResource {
    constructor($mdDialog, $document) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.body = $document[0].body;
    }

    show(ev, resource) {
      const vm = this;
      vm.resource = resource;
      vm.$mdDialog.show({
        controller: 'PreviewResourceCtrl',
        controllerAs: 'previewResource',
        templateUrl: 'components/preview-resource/preview-resource.tpl.html',
        parent: angular.element(vm.body),
        targetEvent: ev,
        clickOutsideToClose: true,
        fullscreen: true
      });
    }
    getObj() {
      const vm = this;
      return vm.resource;
    }
  }

  /**
   * @ngdoc service
   * @name components.service:PreviewResource
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('PreviewResource', PreviewResource);
}());
